var featuredEvent = document.getElementById("featured-event"),
    hamburger = document.getElementById("hamburger"),
    hamburgerOpen = "ramkat-header__nav-hamburger--open",
    header = document.getElementById("header"),
    mainNav = document.getElementById("nav"),
    navClosed = "ramkat-header__main-nav--closed",
    navHeaderTransparent = "ramkat-header__nav-header--transparent",
    rentalsPage = document.getElementById("ramkat-rentals-page"),
    currentScroll,
    headerHeight,
    headerTop,
    lastScroll,
    scrollDiff,
    stop;

window.addEventListener("scroll", checkScrollHeader);

new Swiper(".swiper-container", {
    slidesPerView: 1,
    spaceBetween: 12,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    }
});

if (featuredEvent) {
    document.body.classList.remove("theme--white");
    document.body.classList.add("theme--black");

    header.classList.add(navHeaderTransparent);

    document.body.style.backgroundColor = "#F5F5F5";

    // theme change in header over featured event
    window.addEventListener("scroll", function() {
        var headerTopPadding = parseInt(
            window.getComputedStyle(header).getPropertyValue("padding-top")
        );

        stop = featuredEvent.offsetHeight - headerTopPadding;
        currentScroll = window.pageYOffset;

        if (currentScroll > stop) {
            document.body.classList.remove("theme--black");
            document.body.classList.add("theme--white");

            header.classList.remove(navHeaderTransparent);
        } else {
            document.body.classList.add("theme--black");
            document.body.classList.remove("theme--white");

            header.classList.add(navHeaderTransparent);
        }
    });
}

if (rentalsPage) {
    var form = document.getElementsByClassName("ramkat-rentals-form__form")[0],
        submitArrow = document.getElementsByClassName("ramkat-rentals-form__submit-arrow")[0],
        fail = document.getElementById("ramkat-rentals-form__fail-message"),
        thankYou = document.getElementById("ramkat-rentals-form__thank-you-message"),
        fields = Array.from(document.getElementsByClassName("form-field")),
        pattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    form.onsubmit = async function(event) {
        event.preventDefault();

        function formSuccess() {
            form.classList.add("ramkat-rentals-form__form--close");

            setTimeout(function(){
                form.classList.add("ramkat-rentals-form__form--hide");
                thankYou.classList.add("ramkat-rentals-form__thank-you-message--show");

                setTimeout(function(){
                    thankYou.classList.add("ramkat-rentals-form__thank-you-message--open");
                },50);
            }, 1000);
        }

        var passed = true,
            rc = document.getElementById('g-recaptcha-response');

        fields.forEach(function(item, index) {
            if (!item.value ||
                (item.name == "fromEmail" && !pattern.test(item.value)) ||
                (item.name == "message[Phone]" && (!item.value.match(/\d/g) || item.value.match(/\d/g).length < 7))
            ) {
                passed = false;
                item.classList.add('error');
            } else {
                item.classList.remove('error');
            }
        });

        if (!passed) {
            return;
        }

        // if (!rc.value) {
        //     alert("Please prove that you're a human.");
        //     return;
        // }

        window.playAnimation = true;
        var direction = 1;

        var submitAnimation = function(){
            submitArrow.style.backgroundPosition = (direction ? "" : "-") + (submitArrow.offsetWidth / 2) + "px center";
            direction = direction ? 0 : 1;

            if (window.playAnimation) {
                setTimeout(submitAnimation, 600);
            } else {
                submitArrow.style.backgroundPosition = "0px center";
            }
        };

        setTimeout(submitAnimation, 600);

        var response = await fetch(form.getAttribute('action'), {
            method: form.method,
            body: new URLSearchParams(new FormData(form)),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        var result = await response;

        if (response.status == 200 && response.ok) {
            formSuccess();
        } else {
            console.error(response.statusText);
            console.log(response);
            form.style.opacity = "0.3";
            fail.style.display = "block";
        }

        window.playAnimation = false;
    };
}

// the hamburger and nav
function toggleNav() {
    // nav opened
    if (hamburger.classList.contains(hamburgerOpen)) {
        hamburger.classList.remove(hamburgerOpen);
        header.classList.remove(navHeaderTransparent);
        mainNav.classList.add(navClosed);
        document.body.classList.remove("ramkat__body--freeze");
        window.removeEventListener("touchmove", preventScroll, { passive: false });
    } else {
        // nav closed
        hamburger.classList.add(hamburgerOpen);
        header.classList.add(navHeaderTransparent);
        mainNav.classList.remove(navClosed);
        document.body.classList.add("ramkat__body--freeze");
        window.addEventListener("touchmove", preventScroll, { passive: false });
    }
}

function preventScroll(evt) {
    evt.preventDefault();
    evt.stopPropagation();
}

function checkScrollHeader() {
    currentScroll = window.pageYOffset;
    scrollDiff = lastScroll - currentScroll;
    headerTop = parseInt(window.getComputedStyle(header).getPropertyValue("top")) + scrollDiff;

    if (currentScroll <= 0) {
        // At top of page: fix header to top.
        header.style.top = "0px";
    } else if (scrollDiff > 0) {
        // Scrolled up: slide header in.
        header.style.top = (headerTop > 0 ? 0 : headerTop) + "px";
    } else {
        // Scrolled down: slide header out.
        header.style.top = (Math.abs(headerTop) > headerHeight ? -headerHeight : headerTop) + "px";
        headerHeight = header.offsetHeight;
        lastScroll = window.pageYOffset;
    }

    lastScroll = currentScroll;
}

function submitRentalsForm(event) {

}
